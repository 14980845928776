import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Row, Col, Card, Tour, Select } from 'antd';
import {
	useSetAddAnnounceMutation,
	useGetLocationsQuery,
	useGetDepartmentsQuery,
	useGetUnitsQuery,
} from '../../store/api';
import AnnounceCard from './announceCard';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const Announce = () => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const [openTour, setOpenTour] = useState(false);
	const department = Form.useWatch('department_id', form);
	const location = Form.useWatch('location_id', form);

	const [addAnnounce, { isLoading: addAnnounceLoading, errors }] = useSetAddAnnounceMutation();
	const { data: locationsData, isLoading: isLocationLoading } = useGetLocationsQuery();
	const { data: getDepartmentsData, isLoading: isDepartmentsLoading } = useGetDepartmentsQuery(
		{
			locationId: location,
		},
		{ refetchOnMountOrArgChange: true },
	);
	const { data: units, isLoading: isSectionLoading } = useGetUnitsQuery(
		{
			companyDepartmentId: department,
		},
		{ refetchOnMountOrArgChange: true, skip: !department },
	);

	// Refs for tour targets
	const titleRef = useRef();
	const formRef = useRef();
	const pastAnnouncementsRef = useRef();

	useEffect(() => {
		// Show tour only on first visit to announce page
		const hasSeenAnnounceTour = localStorage.getItem('hasSeenAnnounceTour');
		if (!hasSeenAnnounceTour) {
			setOpenTour(true);
			localStorage.setItem('hasSeenAnnounceTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.announce_page.title'),
			description: t('tour.announce_page.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.announce_form.title'),
			description: t('tour.announce_form.description'),
			target: () => formRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.announce_list.title'),
			description: t('tour.announce_list.description'),
			target: () => pastAnnouncementsRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	const onFinish = async (values) => {
		console.log('Success:', values);
		await addAnnounce(values);
		form.resetFields();
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Col xs={22} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('announce.title')}></PageTitle>
				</div>
			</Col>
			<Col xs={22} xl={22} style={{ marginTop: 20, marginBottom: 20 }}>
				<div ref={formRef}>
					<Card>
						<Form
							name="basic"
							layout="vertical"
							initialValues={{
								remember: false,
							}}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							autoComplete="off"
							form={form}
						>
							<Form.Item
								label={t('announce.an_title')}
								name="topic"
								rules={[
									{
										required: true,
										message: 'Lütfen Duyuru Başlığı Giriniz!',
									},
								]}
							>
								<Input name="topic" />
							</Form.Item>
							<Form.Item
								label={t('announce.an_content')}
								name="comment"
								rules={[
									{
										required: true,
										message: 'Lütfen Duyuru İçeriği Giriniz!',
									},
								]}
							>
								<Input.TextArea name="comment" />
							</Form.Item>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item name="location_id" label="Lokasyon">
										<Select
											options={locationsData?.locations || []}
											fieldNames={{ label: 'location_name', value: 'id' }}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="department_id" label="Departman">
										<Select
											options={getDepartmentsData?.departments || []}
											fieldNames={{ label: 'department_name', value: 'id' }}
										/>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item name="unit_id" label="Birim">
										<Select
											options={units?.data.units || []}
											fieldNames={{ label: 'unitName', value: 'id' }}
										/>
									</Form.Item>
								</Col>
							</Row>

							<Form.Item>
								<Button loading={addAnnounceLoading} type="primary" htmlType="submit">
									{t('announce.an_button')}
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</div>
			</Col>
			<Col xs={22} xl={22}>
				<div ref={pastAnnouncementsRef}>
					<PageTitle title={t('announce.an_past')}></PageTitle>
					<Row>
						<Col className="gutter-row" xs={24} mb={4}>
							<AnnounceCard />
						</Col>
					</Row>
				</div>
			</Col>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</>
	);
};

export default Announce;
