import React from 'react';
import LoginScreen from './pages/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
// import HomeScreen from './pages/home';
// import ProfileScreen from './pages/Profile';
import ProtectedRoute from './routing/';
// import FirmScreen from './pages/FirmInfo';
// import LogoutScreen from './pages/Logout';
// import ForgotPassword from './pages/ForgotPassword';
import LivingWageSurvey from './pages/LivingWageForm';
import Home from './pages/Home';
import Application from './pages/Applications';
import Layout from './components/Layout';
import Complaint from './pages/Complaint';
import Offer from './pages/Offer';
import Survey from './pages/Survey';
import Announce from './pages/Announce';
import SurveySeason from './pages/SurveySeason';
import Settings from './pages/Settings';
import ComplaintForm from './pages/ComplaintForm';
import ReportPage from './pages/ReportPage';
import WorkersList from './pages/WorkersList';
import AdminLogin from './pages/AdminPage/Login';
import AdminHome from './pages/AdminPage/Home';
import AdminLayout from './components/AdminLayout';
import { useSelector } from 'react-redux';
import CompanyDetail from './pages/AdminPage/CompanyDetail';
import CreatePassword from './pages/CreatePassword';
import SurveyListPage from './pages/AdminPage/SurveyListPage';
import SurveyDetail from './pages/SurveyDetail';
import SecondReportScreen from './pages/SurveyDetail/secondReport';
import AdminWorkerList from './pages/AdminPage/WorkerList';
function App() {
	const { userToken, userInfo } = useSelector((state) => state.auth);
	return (
		<Router>
			<Routes>
				<Route element={<ProtectedRoute isProtected={false} />}>
					<Route path="/login" element={<LoginScreen />} />
					<Route path="/admin/login" element={<AdminLogin />} />
				</Route>
				<Route element={<ProtectedRoute isProtected={true} userRole={'ADMIN'} />}>
					<Route element={<AdminLayout />}>
						<Route path="/admin" element={<AdminHome />} />
						<Route replace path="/company-detail/:companyId" element={<CompanyDetail />} />
						<Route path="/survey-list" element={<SurveyListPage />} />
						<Route path="/worker-list/:companyId" element={<AdminWorkerList />} />
					</Route>
				</Route>
				<Route element={<ProtectedRoute isProtected={true} />}>
					<Route element={<Layout />}>
						<Route path="/" element={<Home />} />
						<Route path="/complaints" element={<Complaint />} />
						<Route path="/offers" element={<Offer />} />
						<Route path="/survey" element={<Survey />} />
						<Route path="/announce" element={<Announce />} />
						<Route path="/survey/:season" element={<SurveySeason />} />
						<Route path="/settings" element={<Settings />} />
						<Route path="/reports" element={<ReportPage />} />
						<Route path="/workers-list" element={<WorkersList />} />
						<Route path="/survey-detail/:id" element={<SurveyDetail />} />
						<Route path="/second-reports/:id" element={<SecondReportScreen />} />
					</Route>
				</Route>
				<Route path="/living-wage-form/:id" element={<LivingWageSurvey />} />
				<Route path="/complaint/:id" element={<ComplaintForm />} />
				<Route path="/application" element={<Application />} />
				<Route path="/create-password/:pass_token" element={<CreatePassword />} />
				<Route path="*" element={<Navigate to="/" replace />} />
			</Routes>
		</Router>
	);
}

export default App;
