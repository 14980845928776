import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './store/store';
import { Provider } from 'react-redux';
import './i18n';
import { createGlobalStyle } from 'styled-components';
import { Normalize, normalize } from 'styled-normalize';
import { ConfigProvider } from 'antd';

const GlobalStyle = createGlobalStyle`
	* {
		margin: 0;
		padding: 0;
	}
	#root{
		min-height: 100vh; 
	}
	${normalize};
`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<ConfigProvider
			theme={{
				components: {
					Statistic: {
						contentFontSize: '16px',
						titleFontSize: '13px',
					},
				},
			}}
		>
			<Provider store={store}>
				<Normalize />
				<App />
				<GlobalStyle />
			</Provider>
		</ConfigProvider>
	</React.StrictMode>,
);
