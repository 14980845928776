import { Button, Divider, Form, Input, Modal, Select, Space, Table } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import {
	useAddDepartmentAndUnitsMutation,
	useGetDepartmentsQuery,
	useGetLocationsQuery,
	useLazyGetUnitsQuery,
} from '../../store/api';
import { useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { useTranslation } from 'react-i18next';

const AddUnit = () => {
	const { t } = useTranslation();
	const { data: locationsData, isLoading: isLocationLoading } = useGetLocationsQuery();
	const [addDepartmentAndUnits] = useAddDepartmentAndUnitsMutation();
	const [getUnit, { data: getUnitData }] = useLazyGetUnitsQuery();
	const [departmentSearch, setDepartmentSearch] = useState('');
	const [addDepartmentText, setAddDepartmantText] = useState('');
	const [addDepartment, setAddDepartmant] = useState();
	const [searchTerm] = useDebounce(departmentSearch, 400);
	const [isAddUnitModalOpen, setIsAddUnitModalOpen] = useState(false);
	const [form] = Form.useForm();
	const department = Form.useWatch('department', form);
	const location = Form.useWatch('location', form);
	const units = Form.useWatch('units', form);

	const { data, isFetching, isLoading } = useGetDepartmentsQuery(
		{ departmentName: searchTerm, locationId: location },
		{
			refetchOnMountOrArgChange: true,
		},
	);

	useEffect(() => {
		form.setFieldsValue({ units: undefined });
		if (department !== undefined) {
			getUnit({ companyDepartmentId: department });
		}
	}, [department]);

	useEffect(() => {
		form.setFieldsValue({ department: undefined });
	}, [location]);

	useEffect(() => {
		if (locationsData?.locations?.length > 0) {
			form.setFieldValue('location', locationsData.locations[0].id);
		}
	}, [locationsData]);

	const submit = async (values) => {
		addDepartmentAndUnits(values);
		handleClose();
	};

	const handleClose = () => {
		form.resetFields();
		if (locationsData?.locations?.length > 0) {
			form.setFieldValue('location', locationsData.locations[0].id);
		}
		setIsAddUnitModalOpen(false);
	};

	const departments = useMemo(() => {
		let res = [];
		if (data !== undefined) {
			res = data.departments.map((item) => ({ value: item.id, label: item.department_name }));
		}

		if (addDepartment) {
			res.push({ id: addDepartment, value: addDepartment });
		}

		return res;
	}, [data, addDepartment]);

	const onClickAddDepartment = () => {
		setAddDepartmant(addDepartmentText);
		setAddDepartmantText('');
	};

	const columns = [
		{
			title: 'Bölüm Adı',
			dataIndex: 'unitName',
			key: 'unitName',
		},
		{
			title: 'Çalışan Sayısı',
			dataIndex: 'workerCount',
			key: 'workerCount',
		},
		{
			title: t('edit'),
			key: 'delete',
			sorter: true,
			render: (item, index, row) => (
				<Space size="middle">
					<Button
						danger
						size="small"
						onClick={() => console.log(item.id)}
						icon={<DeleteOutlined />}
					></Button>
				</Space>
			),
		},
	];

	return (
		<>
			<Button onClick={() => setIsAddUnitModalOpen(true)} size="sm" type="link" className="me-2">
				{t('add_department')}
			</Button>
			<Modal
				okButtonProps={{
					htmlType: 'submit',
					form: 'add-unit-form',
					disabled: location === undefined || department === undefined,
				}}
				open={isAddUnitModalOpen}
				onCancel={handleClose}
				onClose={handleClose}
				title={t('addUnitModal.title')}
			>
				<Form id="add-unit-form" form={form} onFinish={submit}>
					<Form.Item name="location" label={t('addUnitModal.location')}>
						<Select
							options={
								locationsData?.locations?.map((item) => ({
									value: item.id,
									label: item.location_name,
								})) || []
							}
							loading={isLocationLoading}
							allowClear={false}
							defaultActiveFirstOption={true}
						/>
					</Form.Item>
					<Form.Item name="department" label={t('addUnitModal.department')}>
						<Select
							options={departments}
							showSearch={true}
							loading={isFetching || isLoading}
							onSearch={(text) => setDepartmentSearch(text)}
							filterOption={false}
							dropdownRender={(menu) => (
								<>
									{menu}
									<>
										<Divider
											style={{
												margin: '8px 0',
											}}
										/>
										<Space
											style={{
												padding: '0 8px 4px',
											}}
										>
											<Input
												placeholder={t('addUnitModal.department_name')}
												onKeyDown={(e) => e.stopPropagation()}
												onChange={(e) => setAddDepartmantText(e.target.value)}
												value={addDepartmentText}
											/>
											<Button type="text" icon={<PlusOutlined />} onClick={onClickAddDepartment}>
												{t('addUnitModal.add_new_department')}
											</Button>
										</Space>
									</>
								</>
							)}
						/>
					</Form.Item>
					<Space
						direction="vertical"
						size="middle"
						style={{
							display: 'flex',
						}}
					>
						{getUnitData?.data.units.length > 0 && (
							<Table
								rowKey={'id'}
								columns={columns}
								dataSource={getUnitData?.data.units}
								pagination={false}
							/>
						)}

						<Form.List name={'units'}>
							{(fields, { add, remove }, { errors }) => (
								<>
									<Button
										disabled={department === undefined}
										style={{ marginBottom: 16 }}
										icon={<PlusOutlined />}
										onClick={() => add()}
									>
										{t('addUnitModal.add_new_unit')}
									</Button>
									{fields.map((field, index) => (
										<Space.Compact
											style={{
												width: '100%',
											}}
											key={index}
										>
											<Form.Item {...field} style={{ width: '100%' }}>
												<Input />
											</Form.Item>
											<Button
												danger
												icon={<DeleteOutlined />}
												onClick={() => remove(field.name)}
											></Button>
										</Space.Compact>
									))}
								</>
							)}
						</Form.List>
					</Space>
				</Form>
			</Modal>
		</>
	);
};

export default AddUnit;
