import { Spinner } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { useSelector } from 'react-redux';
import { useCreateQrCodeMutation, useGetQrCodeQuery } from '../../store/api';
import { useMemo } from 'react';
import { Col, Row, Divider, Card, Button } from 'antd';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const Settings = () => {
	const { t } = useTranslation();
	const { company } = useSelector((state) => state.auth);
	const { data: qrData, refetch, isFetching } = useGetQrCodeQuery();
	const [createQrCode, { data, isLoading }] = useCreateQrCodeMutation();

	const onClickCreateQrCode = () => {
		createQrCode();
	};

	const loading = useMemo(() => isFetching || isLoading, [isFetching, isLoading]);
	const qrId = useMemo(() => data?.qrId || qrData?.qrId, [data, qrData]);
	return (
		<>
			<Col xs={24} xl={16}>
				<PageTitle title={t('settings.title')}></PageTitle>
			</Col>

			<Col xs={24} xl={16} style={{ marginBottom: '25px' }}>
				<Card title={t('settings.complaint_qr')}>
					{qrId ? (
						<Row>
							<Col xl={4}>
								<div style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}>
									<QRCode
										size={256}
										style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
										value={`${process.env.REACT_APP_QR_LINK}complaint/${qrId}`}
										viewBox={`0 0 256 256`}
									/>
								</div>
							</Col>
							<Col xl={8}>Link : {`${process.env.REACT_APP_QR_LINK}complaint/${qrId}`}</Col>
						</Row>
					) : (
						t('settings.no_qr')
					)}
					<Button type="primary" onClick={onClickCreateQrCode} disabled={loading}>
						{loading && (
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-4"
							/>
						)}
						{t('settings.button')}
					</Button>
				</Card>
			</Col>

			<Col xs={24} xl={16}>
				<Card title={t('settings.livingwage_qr')}>
					{qrId ? (
						<Row>
							<Col lg={4}>
								<div style={{ height: 'auto', margin: '0 auto', maxWidth: 128, width: '100%' }}>
									<QRCode
										size={256}
										style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
										value={`${process.env.REACT_APP_QR_LINK}living-wage-form/${qrId}`}
										viewBox={`0 0 256 256`}
									/>
								</div>
							</Col>
							<Col lg={8}>Link : {`${process.env.REACT_APP_QR_LINK}living-wage-form/${qrId}`}</Col>
						</Row>
					) : (
						t('settings.no_qr')
					)}
					<Button type="primary" onClick={onClickCreateQrCode} loading={loading} disabled={loading}>
						{/* {loading && (
							<Spinner
								as="span"
								animation="border"
								size="sm"
								role="status"
								aria-hidden="true"
								className="me-4"
							/>
						)} */}
						{t('settings.button')}
					</Button>
				</Card>
			</Col>
		</>
	);
};

export default Settings;
