import { Table, Spin, Alert, Col } from 'antd';
import { useGetAllSurveyForAdminQuery } from '../../../store/api.js';
import CompanyAndSurveySelectModal from './appendSurvey.js';
import { useEffect } from 'react';
const SurveyListPage = () => {
	const { data: surveys, error, isLoading, refetch } = useGetAllSurveyForAdminQuery({});

	useEffect(() => {
		console.log('surveys:', surveys);
		refetch();
	}, [surveys]);

	const columns = [
		{
			title: 'Anket Adı',
			dataIndex: 'SName',
			key: 'SName',
		},
		{
			title: 'Şirket Adı',
			dataIndex: 'CName',
			key: 'description',
		},
		{
			title: 'Anket ID',
			dataIndex: 'surveyGuid',
			key: 'createdAt',
		},
	];

	if (isLoading) {
		// eslint-disable-next-line react/jsx-filename-extension
		return <Spin tip="Yükleniyor..." />;
	}

	if (error) {
		return (
			<Alert
				message="Hata"
				description="Anket verileri yüklenirken bir hata oluştu."
				type="error"
				showIcon
			/>
		);
	}

	return (
		<Col span={24} lg={22} style={{ marginTop: 20 }}>
			<Table dataSource={surveys.result} columns={columns} rowKey="id" pagination={false} />
			<CompanyAndSurveySelectModal onSurveyListUpdate={() => refetch()} />
		</Col>
	);
};

export default SurveyListPage;
