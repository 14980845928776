import styled from 'styled-components';

const ListItem = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 20px;
`;

export { ListItem };
