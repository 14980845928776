import React, { useState, useRef, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import CustomModal from '../../components/Modals';
import { useGetComplaintQuery } from '../../store/api';
import ComplaintCard from '../../components/ComplaintCard';
import ExportExcelModal from '../../components/ExportExcelModal';
import PageTitle from '../../components/PageTitle';
import { Row, Col, Button, Alert, Tour } from 'antd';
import { useTranslation } from 'react-i18next';

const Complaint = () => {
	const { t } = useTranslation();
	const { data: complaint, isLoading } = useGetComplaintQuery();
	const [modalData, setModalData] = useState();
	const [openExportExcel, setOpenExportExcel] = useState(false);
	const [openTour, setOpenTour] = useState(false);

	// Refs for tour targets
	const titleRef = useRef();
	const exportRef = useRef();
	const cardRef = useRef();

	useEffect(() => {
		// Show tour only on first visit to complaints page
		const hasSeenComplaintTour = localStorage.getItem('hasSeenComplaintTour');
		if (!hasSeenComplaintTour) {
			setOpenTour(true);
			localStorage.setItem('hasSeenComplaintTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.complaint_page.title'),
			description: t('tour.complaint_page.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.export_excel.title'),
			description: t('tour.export_excel.description'),
			target: () => exportRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.complaint_card.title'),
			description: t('tour.complaint_card.description'),
			target: () => cardRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	return (
		<>
			<Col xs={22} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('complaint.title')}>
						<div>
							<Button ref={exportRef} onClick={() => setOpenExportExcel(true)}>
								{t('export_excel')}
							</Button>
						</div>
					</PageTitle>
				</div>
			</Col>

			{complaint?.message && (
				<Col xs={2} sm={4} md={6} lg={8} xl={16}>
					<Alert description={t('complaint.there_is_not_complaints')} type="info" showIcon />
				</Col>
			)}
			{isLoading && (
				<Col xs={2} sm={4} md={6} lg={8} xl={16}>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">{t('complaint.loading')}</span>
					</Spinner>
				</Col>
			)}

			<Col xs={22} xl={22}>
				<Row justify="start" gutter={[16, 16]}>
					{complaint?.complaint
						?.filter((val) => val.complaintStatus === 0)
						.map((item, index) => {
							return (
								<Col
									style={{ marginBottom: 20 }}
									key={item.id}
									xs={24}
									sm={12}
									md={12}
									lg={8}
									xl={{ span: 6, offset: index % 3 ? 1 : 0 }}
								>
									<div ref={index === 0 ? cardRef : null}>
										<ComplaintCard
											item={item}
											onClick={() => setModalData({ ...item, action: true })}
										/>
									</div>
								</Col>
							);
						})}
				</Row>
			</Col>
			<CustomModal
				data={modalData}
				isShow={modalData !== undefined}
				handleClose={() => setModalData(undefined)}
			/>
			<ExportExcelModal
				open={openExportExcel}
				onClose={() => setOpenExportExcel(false)}
				complaintStatus={0}
				choiseType="complaint"
			/>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</>
	);
};

export default Complaint;
