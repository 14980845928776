import { Card, Form, Select, Row, Col, Button, Collapse, List } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useGetSurveyQuestionReportQuery, useGetTotalParticipantsQuery } from '../../store/api';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SurveyFilter from './SurveyFilter';

const { Option } = Select;
const { Panel } = Collapse;

const SurveyDetail = () => {
	const { id } = useParams();
	const { data, isLoading, error } = useGetSurveyQuestionReportQuery({ id });
	const { data: totalParticipants } = useGetTotalParticipantsQuery({ surveyGuid: id });
	const Navigate = useNavigate();
	const { t } = useTranslation();
	const columns = [
		{
			title: 'Question',
			dataIndex: 'question',
			key: 'question',
		},
		{
			title: 'Gender',
			dataIndex: 'gender',
			key: 'gender',
		},
	];

	const secondReports = () => {
		Navigate(`/second-reports/${id}`);
	};
	return (
		<>
			<Col lg={22}>
				<Row justify="space-between" align="middle">
					<Col lg={19}>
						<h1>{t('surveyDetail.title')}</h1>
					</Col>
					<Col lg={3} align="right">
						<Button type="primary" size="sm" onClick={() => secondReports()}>
							{t('surveyDetail.reportButton')}
						</Button>
					</Col>
				</Row>
			</Col>
			<SurveyFilter
				survey={data}
				totalParticipants={totalParticipants?.total_participants}
				questionCount={totalParticipants?.total_questions || 0}
			/>
			<Col style={{ marginTop: 20 }} lg={22}>
				<Card title="Survey Question Report">
					<Collapse
						bordered={false}
						expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					>
						{data?.result.map((item, index) => (
							<Panel
								header={
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div> {item.GroupName} </div>
										<div> %{Number(item.GroupTotalPercentage).toFixed(2)} </div>
									</div>
								}
								key={index}
								style={{ fontWeight: 700, fontSize: 16 }}
							>
								<List>
									{JSON.parse(item?.QuestionsAndPercentages).map((question, qIndex) => (
										<List.Item
											style={{ paddingLeft: 15, fontWeight: 500, fontSize: 14 }}
											key={qIndex}
											actions={[<span>% {Number(question.positivePercentage).toFixed(2)}</span>]}
										>
											<div>{question.question}</div>
										</List.Item>
									))}
								</List>
							</Panel>
						))}
					</Collapse>
				</Card>
			</Col>
		</>
	);
};

export default SurveyDetail;
