import styled from 'styled-components';

const PageTitleContainer = styled.div`
	width: 100%;
	margin: 20px 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #aaa;
`;
const Title = styled.h4`
	font-size: 24px;
	color: #000;
	margin: 20px 0;
`;

export { PageTitleContainer, Title };
