import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetComplaintQuery } from '../../store/api';
import { useGetOffersQuery } from '../../store/api';
import { useSelector } from 'react-redux';
import { Card, Button, Divider, Row, Statistic, Col, Tour } from 'antd';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';

const Home = () => {
	const { data: complaint } = useGetComplaintQuery();
	const { data: offer } = useGetOffersQuery();
	const { userInfo } = useSelector((state) => state.auth);
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	// Refs for tour targets
	const complaintCardRef = useRef();
	const offerCardRef = useRef();
	const titleRef = useRef();
	const buttonRef = useRef();

	useEffect(() => {
		// Show tour only on first visit
		const hasSeenTour = localStorage.getItem('hasSeenHomeTour');
		if (!hasSeenTour) {
			setOpen(true);
			localStorage.setItem('hasSeenHomeTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.welcome.title'),
			description: t('tour.welcome.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.complaints.title'),
			description: t('tour.complaints.description'),
			target: () => complaintCardRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.offers.title'),
			description: t('tour.offers.description'),
			target: () => offerCardRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	return (
		<>
			<Col xs={22} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('home.title')}>
						<Button ref={buttonRef} type="primary" onClick={() => setOpen(true)}>
							{t('tour.start')}
						</Button>
					</PageTitle>
				</div>
			</Col>

			<Row>
				<Col span={24}></Col>
			</Row>

			<Col xs={22} xl={22}>
				<Row gutter={12}>
					<Col xs={24} sm={12} md={12} lg={12} style={{ marginBottom: '16px' }}>
						<Card
							ref={complaintCardRef}
							title={
								complaint?.complaint.length > 0
									? t('home.there_is_complaints')
									: t('home.there_is_not_complaints')
							}
						>
							{t('home.complaint_info')}
							<Statistic
								title={t('home.pending_complaints')}
								value={complaint?.complaint.length}
								precision={0}
								valueStyle={{ color: '#f10b0a', fontSize: 36, fontWeight: 'bold' }}
							/>
							<Button style={{ marginTop: 16 }} color="danger" variant="solid">
								{t('home.complaint_button')}
							</Button>
						</Card>
					</Col>
					<Col xs={24} sm={12} md={12} lg={12}>
						<Card
							ref={offerCardRef}
							title={
								offer?.offer.length > 0 ? t('home.there_is_offers') : t('home.there_is_not_offers')
							}
						>
							{t('home.offer_info')}
							<Statistic
								title={t('home.pending_offers')}
								value={offer?.offer.length}
								precision={0}
								valueStyle={{ color: '#3f8600', fontSize: 36, fontWeight: 'bold' }}
							/>
							<Button
								onClick={() => navigate('/offers')}
								color="primary"
								variant="filled"
								style={{ marginTop: '20px' }}
							>
								{t('home.offer_button')}
							</Button>
						</Card>
					</Col>
				</Row>
			</Col>
			<Tour open={open} onClose={() => setOpen(false)} steps={steps} />
		</>
	);
};

export default Home;
