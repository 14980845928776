const ops = [
	{
		value: '1',
		label: 'Kategori Yok',
	},
	{
		value: '2',
		label: 'Ücret Beklentisi',
	},
	{
		value: '3',
		label: 'Mobbing',
	},
	{
		value: '4',
		label: 'Temizlik',
	},
	{
		value: '5',
		label: 'Sağlık',
	},
	{
		value: '6',
		label: 'Taciz',
	},
	{
		value: '7',
		label: 'Hakaret',
	},
];

export default ops;
