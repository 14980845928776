import { Col, Card, Form, Select, Row, Statistic } from 'antd';
import { useGetLocationsQuery, useGetDepartmentsQuery, useGetUnitsQuery } from '../../store/api';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const SurveyFilter = ({ survey, totalParticipants, questionCount, setSurvey }) => {
	const [form] = Form.useForm();
	const department = Form.useWatch('department', form);
	const location = Form.useWatch('location', form);
	const { data: locations, isLoading: isLocationLoading } = useGetLocationsQuery();
	const { data: departments, isLoading: isDepartmentLoading } = useGetDepartmentsQuery(
		{
			locationId: location,
		},
		{ refetchOnMountOrArgChange: true },
	);
	const { data: units, isLoading: isSectionLoading } = useGetUnitsQuery(
		{
			companyDepartmentId: department,
			skip: !department,
		},
		{ refetchOnMountOrArgChange: true, skip: !department },
	);
	const { t } = useTranslation();

	const submit = (values) => {
		// setSurvey({ ...survey, ...values });
	};
	return (
		<Col lg={22}>
			<Row gutter={[16, 16]}>
				<Col span={24}>
					<Card title={t('surveyDetail.surveyInfo')}>
						<Row gutter={16}>
							<Col span={12}>
								<Statistic
									title={t('surveyDetail.totalParticipants')}
									value={totalParticipants || 0}
								/>
							</Col>
							<Col span={12}>
								<Statistic title={t('surveyDetail.totalQuestions')} value={questionCount || 0} />
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={24}>
					<Card title="Filter">
						<Form layout="vertical" form={form} onFinish={submit}>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label="Location" name="location">
										<Select
											placeholder="Select location"
											options={locations?.locations || []}
											fieldNames={{ label: 'location_name', value: 'id' }}
											loading={isLocationLoading}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Department" name="department">
										<Select
											placeholder="Select department"
											options={departments?.departments || []}
											fieldNames={{ label: 'department_name', value: 'id' }}
											loading={isDepartmentLoading}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label="Section" name="section">
										<Select
											placeholder="Select section"
											options={units?.data.units || []}
											fieldNames={{ label: 'unitName', value: 'id' }}
											loading={isDepartmentLoading}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Statü" name="collar">
										<Select placeholder="Select collar type">
											<Option value="white">Beyaz Yaka</Option>
											<Option value="blue">Mavi Yaka</Option>
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item label="Gender" name="gender">
										<Select placeholder="Select gender">
											<Option value="male">Erkek</Option>
											<Option value="female">Kadın</Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item label="Survey Group" name="survey_group_name">
										<Select
											placeholder="Select section"
											options={survey?.result || []}
											fieldNames={{ label: 'GroupName', value: 'MainGroupID' }}
											loading={isDepartmentLoading}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</Col>
	);
};

export default SurveyFilter;
