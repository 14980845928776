import React, { useEffect, useRef } from 'react';
import { Card, Select, Button, Form, Row, Col } from 'antd';
import styled from 'styled-components';
import Chart from 'chart.js/auto';

const { Option } = Select;

const StyledCard = styled(Card)`
	margin-bottom: 20px;
`;

const StyledSelect = styled(Select)`
	width: 100%;
`;

const StatusBarChart = () => {
	const chartRef = useRef(null);

	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');

		// Bar chart data and configuration
		const chart = new Chart(ctx, {
			type: 'bar',
			data: {
				labels: ['Genel', 'Mavi Yaka', 'Beyaz Yaka'],
				datasets: [
					{
						label: 'Gelişim',
						data: [70, 55, 90], // Sample data
						backgroundColor: 'rgba(54, 162, 235, 0.7)',
						borderColor: 'rgba(54, 162, 235, 1)',
						borderWidth: 1,
					},
					{
						label: 'Gelecek',
						data: [80, 60, 85], // Sample data
						backgroundColor: 'rgba(255, 99, 132, 0.7)',
						borderColor: 'rgba(255, 99, 132, 1)',
						borderWidth: 1,
					},
				],
			},
			options: {
				responsive: true,
				plugins: {
					legend: {
						position: 'top',
					},
					tooltip: {
						callbacks: {
							label: function (tooltipItem) {
								return `${tooltipItem.dataset.label}: ${tooltipItem.raw}%`;
							},
						},
					},
				},
				scales: {
					x: {
						title: {
							display: true,
							text: 'Statü',
						},
					},
					y: {
						beginAtZero: true,
						title: {
							display: true,
							text: 'Yüzde (%)',
						},
					},
				},
			},
		});

		return () => {
			chart.destroy(); // Cleanup on component unmount
		};
	}, []);

	return (
		<StyledCard title={<h4>Statü bazında gelişim</h4>}>
			<Form layout="vertical">
				<Row gutter={16}>
					<Col xs={24} md={6}>
						<Form.Item label="Lokasyon" name="location">
							<StyledSelect defaultValue="0">
								<Option value="0">Genel</Option>
								<Option value="22">İstanbul</Option>
								<Option value="23">Bolu Fabrika</Option>
								<Option value="24">Ergene Fabrika</Option>
								<Option value="25">Silivri Fabrika</Option>
							</StyledSelect>
						</Form.Item>
					</Col>
					<Col xs={24} md={6}>
						<Form.Item label="Departman" name="department">
							<StyledSelect defaultValue="0">
								<Option value="0">Genel</Option>
							</StyledSelect>
						</Form.Item>
					</Col>
					<Col xs={24} md={6}>
						<Form.Item label="Birim" name="unit">
							<StyledSelect defaultValue="0">
								<Option value="0">Genel</Option>
							</StyledSelect>
						</Form.Item>
					</Col>
					<Col xs={24} md={6}>
						<Form.Item label="Statü" name="status">
							<StyledSelect defaultValue="0">
								<Option value="0">Hepsi</Option>
								<Option value="27">Beyaz Yaka</Option>
								<Option value="28">Mavi Yaka</Option>
							</StyledSelect>
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						<Button
							type="primary"
							htmlType="button"
							id="showReportChart_2"
							style={{ marginTop: 10 }}
						>
							Göster
						</Button>
					</Col>
				</Row>
			</Form>
			<div style={{ marginTop: 50 }}>
				<canvas ref={chartRef} id="myChart"></canvas>
			</div>
		</StyledCard>
	);
};

export default StatusBarChart;
