import React, { useEffect } from 'react';
import { useGetWorkerListForAdminQuery } from '../../../store/api';
import { useParams } from 'react-router-dom';
import { Spin, Alert, Col, Card, Row, Statistic, Badge, Avatar } from 'antd';
import { ClockCircleOutlined } from '@ant-design/icons';

const WorkerList = () => {
	const { companyId } = useParams();
	const {
		data: getWorkerList,
		isSuccess,
		isError,
		isLoading,
	} = useGetWorkerListForAdminQuery({ companyId });

	useEffect(() => {
		console.log(getWorkerList);
	}, []);

	if (isLoading) {
		return <Spin tip="Yükleniyor..." />;
	}

	if (isError) {
		return (
			<Alert
				message="Hata"
				description="Çalışanlar yüklenirken bir hata oluştu."
				type="error"
				showIcon
			/>
		);
	}

	if (isSuccess && getWorkerList?.workersList?.length === 0) {
		return <p>Çalışan bulunamadı.</p>;
	}

	return (
		<>
			<Col xs={22} sm={22} md={22} lg={22} style={{ marginTop: 20 }}>
				<Row gutter={10}>
					{isSuccess &&
						getWorkerList?.workersList?.map((item) => (
							<Col xs={24} sm={12} md={8} lg={6} style={{ marginBottom: 10 }}>
								<Card title={item.phone_number} key={item.id}>
									<Statistic
										style={{ contentFontSize: 20 }}
										value={item.department_name}
										title="Departman"
									/>
									<Statistic value={item.company_location_name} title="Lokasyon" />
									{item.device_token === null ? (
										<Badge count={<ClockCircleOutlined style={{ color: '#f5222d' }} />}></Badge>
									) : (
										<Badge
											className="site-badge-count-109"
											count="Registered"
											style={{ backgroundColor: '#52c41a' }}
										/>
									)}
								</Card>
							</Col>
						))}
				</Row>
			</Col>
		</>
	);
};

export default WorkerList;
