import React, { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
	useGetAnswerAveragesQuery,
	useGetLocationsQuery,
	useGetDepartmentsQuery,
	useGetUnitsQuery,
} from '../../store/api';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Spin, Card, Col, Select, Row } from 'antd';

// Chart.js'e DataLabels plugin'i ekliyoruz
Chart.register(ChartDataLabels);

const SegmentGraphChart = () => {
	// Parametrelerden surveyGuid'i alıyoruz
	const { id } = useParams();
	const { t } = useTranslation();
	const [filters, setFilters] = useState({
		location: undefined,
		department: undefined,
		unit: undefined,
	});

	const { data: response, isLoading } = useGetAnswerAveragesQuery({
		surveyGuid: id,
		locationId: filters.location,
		companyDepartmentId: filters.department,
		unit_id: filters.unit,
	});

	const { data: locations } = useGetLocationsQuery();
	const { data: departments } = useGetDepartmentsQuery(
		filters.location ? { locationId: filters.location } : undefined,
	);
	const { data: units } = useGetUnitsQuery(
		filters.department ? { companyDepartmentId: filters.department } : undefined,
	);

	// Handler for filter changes
	const handleFilterChange = (value, type) => {
		console.log('Filter change:', type, value);
		setFilters((prev) => {
			const newFilters = { ...prev };

			if (value === undefined) {
				// Handle clear
				delete newFilters[type];
				if (type === 'location') {
					delete newFilters.department;
					delete newFilters.unit;
				} else if (type === 'department') {
					delete newFilters.unit;
				}
			} else {
				// Handle selection
				newFilters[type] = value;
				if (type === 'location') {
					delete newFilters.department;
					delete newFilters.unit;
				} else if (type === 'department') {
					delete newFilters.unit;
				}
			}

			console.log('New filters:', newFilters);
			return newFilters;
		});
	};

	// Veriler yüklenene kadar yükleniyor ekranı gösteriyoruz
	if (isLoading) {
		return (
			<div style={{ textAlign: 'center', padding: '20px' }}>
				<Spin size="large" />
			</div>
		);
	}

	// Veriler yüklenmediyse null döndürüyoruz
	if (!response?.success || !response?.segments) {
		return null;
	}

	// API cevabından segmentleri alıyoruz
	const { segments } = response;

	// Grafik verileri
	const chartData = {
		labels: [
			t('surveyDetail.timeSpenders'),
			t('surveyDetail.dedicated'),
			t('surveyDetail.exhausted'),
			t('surveyDetail.professionalThinkers'),
		],
		datasets: [
			{
				data: [
					segments.time_spenders.percentage,
					segments.dedicated.percentage,
					segments.exhausted.percentage,
					segments.professional_thinkers.percentage,
				],
				backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
				hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
			},
		],
	};

	// Grafik ayarları
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					padding: 20,
					font: {
						size: 12,
					},
				},
			},
			tooltip: {
				callbacks: {
					label: function (context) {
						const label = context.label || '';
						const segmentKey =
							context.dataIndex === 0
								? 'time_spenders'
								: context.dataIndex === 1
								? 'dedicated'
								: context.dataIndex === 2
								? 'exhausted'
								: 'professional_thinkers';
						const count = segments[segmentKey].count;
						return `${label}: %${context.raw} (${count} ${t('surveyDetail.participants')})`;
					},
				},
			},
			datalabels: {
				color: '#fff',
				font: {
					weight: 'bold',
					size: 14,
				},
				formatter: (value) => `%${value}`,
			},
		},
	};

	return (
		<Col span={24}>
			<Card
				title={
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<span>{t('surveyDetail.engagementMotivationChart')}</span>
						<span>
							{t('surveyDetail.totalParticipants')}: {response.totalParticipants}
						</span>
					</div>
				}
				style={{ marginBottom: 20 }}
			>
				<Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
					<Col span={8}>
						<Select
							placeholder={t('surveyDetail.selectLocation')}
							style={{ width: '100%' }}
							onChange={(value) => handleFilterChange(value, 'location')}
							allowClear
							value={filters.location}
							options={locations?.locations?.map((loc) => ({
								label: loc.location_name,
								value: loc.id,
							}))}
						/>
					</Col>
					<Col span={8}>
						<Select
							placeholder={t('surveyDetail.selectDepartment')}
							style={{ width: '100%' }}
							onChange={(value) => handleFilterChange(value, 'department')}
							allowClear
							value={filters.department}
							disabled={!filters.location}
							options={departments?.departments?.map((dept) => ({
								label: dept.department_name,
								value: dept.id,
							}))}
						/>
					</Col>
					<Col span={8}>
						<Select
							placeholder={t('surveyDetail.selectUnit')}
							style={{ width: '100%' }}
							onChange={(value) => handleFilterChange(value, 'unit')}
							allowClear
							value={filters.unit}
							disabled={!filters.department}
							options={units?.data.units || []}
							fieldNames={{ label: 'unitName', value: 'id' }}
						/>
					</Col>
				</Row>
				<div style={{ height: '400px', padding: '20px' }}>
					<Doughnut data={chartData} options={options} />
				</div>
			</Card>
		</Col>
	);
};

export default SegmentGraphChart;
