import { Title, PageTitleContainer } from './styled';
const PageTitle = ({ title, children }) => {
	return (
		<PageTitleContainer>
			<Title aria-hidden={true}>{title}</Title>
			{children}
		</PageTitleContainer>
	);
};

export default PageTitle;
