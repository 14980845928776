import { Card, Badge } from 'antd';
import filterBadWords from '../../utils/BadWords';
import { CardMeta } from './styles';
import selectCategory from '../../utils/Category';
import { useMemo } from 'react';

const ComplaintCard = ({ item, onClick }) => {
	const cat = useMemo(() => {
		if (!item.category) return 'Kategorisiz';
		if (item.category === '') return 'Kategorisiz';
		const index = selectCategory.findIndex((val) => val.value === item.category);
		if (index === -1) return 'Kategorisiz';
		return selectCategory[index].label;
	}, [item.category]);

	return (
		<Badge.Ribbon text={cat} color={item.isActive ? 'green' : 'red'}>
			<Card title={filterBadWords(item.topic)} hoverable onClick={onClick}>
				<CardMeta description={filterBadWords(item.comment)} />
			</Card>
		</Badge.Ribbon>
	);
};

export default ComplaintCard;
