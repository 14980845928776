import React from 'react';
import {
	Label,
	LoginContainer,
	InputWrapper,
	Error,
	Alert,
	FormContainer,
	LeftContainer,
	Desc,
} from './styled';
import Spinner from 'react-bootstrap/Spinner';
import { useForm } from 'react-hook-form';
import { Button, Form, Input, theme, Typography, Grid } from 'antd';
import { useAdminLoginMutation } from '../../../store/api';
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const AdminPage = () => {
	const { t, i18n } = useTranslation();
	const [loginUser, { isLoading, error, reset, isSuccess }] = useAdminLoginMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	// const submitForm = async (data) => {
	// 	const res = await loginUser(data);
	// 	console.log('error', error, 'isLoading', isLoading, 'isSuccess', isSuccess);
	// };

	const onFinish = async (values) => {
		const res = await loginUser(values);
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<LoginContainer>
			<div className="h-100  w-50">
				<FormContainer>
					<Form
						name="admin_login"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						layout="vertical"
						requiredMark="optional"
					>
						<Form.Item
							name="user_name"
							rules={[
								{
									required: true,
									message: 'Please input your username!',
								},
							]}
						>
							<Input size="large" prefix={<MailOutlined />} placeholder="Email" />
						</Form.Item>
						<Form.Item
							name="user_password"
							rules={[
								{
									required: true,
									message: 'Please input your Password!',
								},
							]}
						>
							<Input.Password
								size="large"
								prefix={<LockOutlined />}
								type="password"
								placeholder="Password"
							/>
						</Form.Item>
						<Form.Item style={{ marginBottom: '0px' }}>
							<Button size="large" block="true" type="primary" htmlType="submit">
								{isLoading ? t('login.login_redirect') : t('login.login_btn')}
							</Button>
						</Form.Item>
					</Form>
				</FormContainer>
			</div>
		</LoginContainer>
	);
};

export default AdminPage;
