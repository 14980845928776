import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { logout } from '../../store/authSlice';
import {
	BsHouse,
	BsSendDash,
	BsSendPlus,
	BsUiChecksGrid,
	BsMegaphone,
	BsGear,
	BsBoxArrowLeft,
} from 'react-icons/bs';
import { Menu, Row, Col, Button, Flex, Layout as ANTDLayout } from 'antd';
import { useTranslation } from 'react-i18next';
const { Header, Footer, Content } = ANTDLayout;

const Layout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const savedLanguage = localStorage.getItem('language') || 'en'; // Varsayılan olarak İngilizce
		i18n.changeLanguage(savedLanguage);
	}, [i18n]);

	// Dil değiştirme işlemi
	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		localStorage.setItem('language', lng);
	};
	const [current, setCurrent] = useState('home');
	const onClick = (e) => {
		navigate(e.key);
		setCurrent(e.key);
	};

	return (
		<Flex gap="middle" wrap style={{ height: '100vh' }}>
			<ANTDLayout>
				<Header style={{ background: '#f1f1f1' }}>
					<Row justify="space-between" align="center">
						<Col>
							<img
								src={`${process.env.PUBLIC_URL}/images/vow-logo.svg`}
								alt="voiceofworkers logo"
								style={{ padding: '10px 0' }}
							/>
						</Col>
						<Col>
							<Button
								color="default"
								type="link"
								variant="link"
								onClick={() => changeLanguage('en')}
							>
								EN
							</Button>
							|
							<Button type="link" variant="link" onClick={() => changeLanguage('tr')}>
								TR
							</Button>
							<Button size="sm" type="primary" onClick={() => dispatch(logout())}>
								<BsBoxArrowLeft /> {t('logout')}
							</Button>
						</Col>
					</Row>
				</Header>
				<Content>
					<Row style={{ background: '#2e77fb' }} justify="center">
						<Menu
							onClick={onClick}
							mode="horizontal"
							// items={items}
							theme="dark"
							selectedKeys={[current]}
							style={{
								width: '70%',
								textAlign: 'center',
								background: '#2e77fb',
								padding: '20px 0',
								fontSize: '16px',
								fontWeight: '500',
							}}
						>
							<Menu.Item key="/" icon={<BsHouse />}>
								{t('menu.home')}
							</Menu.Item>
							<Menu.Item key="/complaints" icon={<BsSendDash />}>
								{t('menu.complaint')}
							</Menu.Item>
							<Menu.Item key="/offers" icon={<BsSendPlus />}>
								{t('menu.offer')}
							</Menu.Item>
							<Menu.Item key="/reports" icon={<BsHouse />}>
								{t('menu.action')}
							</Menu.Item>
							<Menu.Item key="survey" icon={<BsUiChecksGrid />}>
								{t('menu.survey')}
							</Menu.Item>
							<Menu.Item key="workers-list" icon={<BsBoxArrowLeft />}>
								{t('menu.worker_list')}
							</Menu.Item>
							<Menu.Item key="announce" icon={<BsMegaphone />}>
								{t('menu.news')}
							</Menu.Item>
							<Menu.Item key="settings" icon={<BsGear />}>
								{t('menu.settings')}
							</Menu.Item>
						</Menu>
					</Row>
					<Row justify="center">
						<Outlet />
					</Row>
				</Content>
				<Footer style={{ textAlign: 'center' }}>voiceofworkers 2024</Footer>
			</ANTDLayout>
		</Flex>
	);
};

export default Layout;
