import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Select, Input } from 'antd';
import {
	useGetLocationsQuery,
	useGetDepartmentsQuery,
	useGetUnitsQuery,
	useUpdateWorkerMutation,
} from '../../store/api';
const EditModal = ({ data }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [form] = Form.useForm();
	const department = Form.useWatch('department_id', form);
	const location = Form.useWatch('location', form);

	const [updateWorker, { isLoading }] = useUpdateWorkerMutation();
	const { data: locationsData, isLoading: isLocationLoading } = useGetLocationsQuery();
	const { data: getDepartmentsData, isLoading: isDepartmentsLoading } = useGetDepartmentsQuery(
		{
			locationId: location,
		},
		{ refetchOnMountOrArgChange: true },
	);
	const { data: units, isLoading: isSectionLoading } = useGetUnitsQuery(
		{
			companyDepartmentId: department,
		},
		{ refetchOnMountOrArgChange: true, skip: !department },
	);

	useEffect(() => {
		if (location && location !== data.location_id) {
			form.setFieldValue('department_id', '');
			form.setFieldValue('unit', '');
		}
	}, [location, data.location_id, form]);

	useEffect(() => {
		if (department && department !== data.department_id) {
			form.setFieldValue('unit', '');
		}
	}, [department, data.department_id, form]);

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleCancel = () => {
		setIsModalOpen(false);
		form.resetFields();
	};

	const submit = async (values) => {
		console.log(values);
		if (!values.unit) {
			values.unit = null;
		}
		await updateWorker(values);
		handleCancel();
	};

	return (
		<div>
			<Button onClick={() => showModal()}>Edit</Button>
			<Modal
				title="Çalışan Düzenle"
				open={isModalOpen}
				onCancel={handleCancel}
				htmlType="submit"
				okButtonProps={{ htmlType: 'submit', form: 'editForm', loading: isLoading }}
			>
				<Form
					id="editForm"
					form={form}
					onFinish={submit}
					labelCol={{ span: 6 }}
					wrapperCol={{ span: 16 }}
					initialValues={{
						id: data.id,
						location: data.location_id,
						isActive: data.isActive,
						department_id: data.department_id,
						unit: data.unit_id,
					}}
				>
					<Form.Item name="id" hidden>
						<Input />
					</Form.Item>
					<Form.Item name="isActive" hidden>
						<Input />
					</Form.Item>
					<Form.Item name="location" label="Lokasyon">
						<Select
							options={
								locationsData?.locations?.map((item) => ({
									value: item.id,
									label: item.location_name,
								})) || []
							}
							loading={isLocationLoading}
							allowClear={false}
							defaultActiveFirstOption={true}
						/>
					</Form.Item>
					<Form.Item
						name="department_id"
						label="Departman"
						rules={[{ required: true, message: 'Lütfen departman seçiniz' }]}
					>
						<Select
							placeholder="Select a department"
							allowClear
							options={
								getDepartmentsData?.departments.map((item) => ({
									value: item.id,
									label: item.department_name,
								})) || []
							}
						/>
					</Form.Item>
					<Form.Item name="unit" label="Unit">
						<Select
							placeholder="Select a unit"
							allowClear
							options={units?.data.units || []}
							fieldNames={{ label: 'unitName', value: 'id' }}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default EditModal;
