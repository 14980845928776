import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Card, Form, Select, Button, Row, Col, Table } from 'antd';
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

const { Option } = Select;

// Styled Components
const StyledCard = styled(Card)`
	margin-bottom: 20px;
`;

const StyledCanvas = styled.canvas`
	width: 100%;
	height: 400px;
`;

const VerticalText = styled.p`
	writing-mode: vertical-lr;
	transform: rotate(180deg);
	position: absolute;
	top: 50%;
	left: -40px;
	margin: 0;
`;

const HorizontalText = styled.p`
	text-align: center;
	margin-top: 10px;
`;

// Scatter Chart Data (Mock Data)
const scatterData = [
	{ label: 'EĞİTİM VE GELİŞİM', x: 67.42, y: 80.9, color: 'rgba(17,99,154,1)' },
	{ label: 'İŞİN KENDİSİ VE SORUMLULUK', x: 84.84, y: 82.92, color: 'rgba(255, 206, 86, 1)' },
	{ label: 'BAĞLI BULUNAN İLK YÖNETİCİ', x: 62.67, y: 79.91, color: 'rgba(153, 102, 255, 1)' },
	{ label: 'ŞİRKET İÇİ GENEL İLETİŞİM', x: 63.74, y: 79.5, color: 'rgba(211, 211, 211, 1)' },
	{ label: 'TAKDİR', x: 52.44, y: 80.41, color: 'rgba(2, 154, 158, 1)' },
	{ label: 'ÇALIŞMA KOŞULLARI', x: 64.95, y: 84.47, color: 'rgba(160, 5, 21, 1)' },
	{ label: 'ÇALIŞANLARA SUNULAN HİZMETLER', x: 67.08, y: 83.76, color: 'rgba(68, 167, 248, 1)' },
];

const ActionAnalysisScatter = () => {
	const chartRef = useRef(null);

	useEffect(() => {
		const ctx = chartRef.current.getContext('2d');

		// Scatter Chart Configuration
		const chart = new Chart(ctx, {
			type: 'scatter',
			data: {
				datasets: scatterData.map((item) => ({
					label: item.label,
					data: [{ x: item.x, y: item.y }],
					backgroundColor: item.color,
				})),
			},
			options: {
				responsive: true,
				plugins: {
					tooltip: {
						callbacks: {
							label: (tooltipItem) => {
								const dataPoint = tooltipItem.raw;
								return `${tooltipItem.dataset.label}: X: ${dataPoint.x}, Y: ${dataPoint.y}`;
							},
						},
					},
					legend: { display: true, position: 'top' },
					annotation: {
						annotations: {
							horizontalLine: {
								type: 'line',
								yMin: 33.56,
								yMax: 33.56,
								borderColor: 'blue',
								borderWidth: 2,
								label: {
									content: 'Ortalama 33.56%',
									enabled: true,
									position: 'end',
									backgroundColor: 'blue',
									color: 'white',
								},
							},
							verticalLine: {
								type: 'line',
								xMin: 64.69,
								xMax: 64.69,
								borderColor: 'green',
								borderWidth: 2,
								label: {
									content: 'Ortalama 64.69%',
									enabled: true,
									position: 'end',
									backgroundColor: 'green',
									color: 'white',
								},
							},
						},
					},
				},
				scales: {
					x: {
						title: { display: true, text: 'Memnuniyet (%)' },
						beginAtZero: true,
						min: 0,
						max: 100,
					},
					y: {
						title: { display: true, text: 'Önemlilik (%)' },
						beginAtZero: true,
						min: 0,
						max: 100,
					},
				},
			},
		});

		return () => {
			chart.destroy();
		};
	}, []);

	// Table columns and data
	const columns = [
		{
			title: '#',
			dataIndex: 'color',
			key: 'color',
			render: (color) => <div style={{ backgroundColor: color, width: 20, height: 20 }}></div>,
		},
		{ title: 'Faktör İsmi', dataIndex: 'label', key: 'label' },
		{ title: 'Memnuniyet', dataIndex: 'x', key: 'x', align: 'center' },
		{ title: 'Önemlilik', dataIndex: 'y', key: 'y', align: 'center' },
	];

	return (
		<StyledCard title="Aksiyon Analizi - Scatter">
			<Form layout="vertical">
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item label="Lokasyon">
							<Select defaultValue="0">
								<Option value="0">Genel</Option>
								<Option value="22">İstanbul</Option>
								<Option value="23">Bolu Fabrika</Option>
								<Option value="24">Ergene Fabrika</Option>
								<Option value="25">Silivri Fabrika</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Departman">
							<Select defaultValue="0">
								<Option value="0">Genel</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Birim">
							<Select defaultValue="0">
								<Option value="0">Genel</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item label="Statü">
							<Select defaultValue="0">
								<Option value="0">Hepsi</Option>
								<Option value="27">Beyaz Yaka</Option>
								<Option value="28">Mavi Yaka</Option>
							</Select>
						</Form.Item>
					</Col>
				</Row>
				<Button type="primary">Göster</Button>
			</Form>

			<div style={{ position: 'relative', marginTop: 50 }}>
				<VerticalText>Boyutların genel memnuniyete etkisi</VerticalText>
				<StyledCanvas ref={chartRef} />
				<HorizontalText>Memnuniyet ve Önemlilik Arasındaki Dağılım</HorizontalText>
			</div>

			<Table
				columns={columns}
				dataSource={scatterData}
				pagination={false}
				size="small"
				style={{ marginTop: 20 }}
			/>
		</StyledCard>
	);
};

export default ActionAnalysisScatter;
