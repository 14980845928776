import React from 'react';
import { Col, Button, Spin, Alert } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useGetLivingWageSurveysQuery, useGetSurveyFormCompanyQuery } from '../../store/api';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import { ListItem } from './styled';

const Survey = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useGetLivingWageSurveysQuery();
	const { data: getSurveyForCompanyData } = useGetSurveyFormCompanyQuery();
	const navigate = useNavigate();

	return (
		<>
			<Col lg={22}>
				<PageTitle title={t('survey.title')} />
				{!data && !isLoading && <Alert message={t('survey.there_is_not_survey')} type="error" />}
				{isLoading ? (
					<Spin tip="Loading" />
				) : (
					data &&
					data.map((item, index) => (
						<ListItem key={index}>
							<div>{item.season} - Living wage anketi</div>
							<div className="button-container">
								<Button
									type="primary"
									size="small"
									onClick={() => navigate(`/survey/${item.season}`)}
								>
									{t('detail')}
								</Button>
							</div>
						</ListItem>
					))
				)}
			</Col>

			<Col lg={22}>
				{getSurveyForCompanyData &&
					getSurveyForCompanyData.result.map((item, index) => {
						return (
							<ListItem key={index}>
								<div>{item.name}</div>
								<div className="button-container">
									<Button
										type="primary"
										size="small"
										onClick={() => navigate(`/survey-detail/${item.guid}`)}
									>
										{t('detail')}
									</Button>
								</div>
							</ListItem>
						);
					})}
			</Col>
		</>
	);
};

export default Survey;
