import React, { useEffect, useState, useMemo } from 'react';
import {
	useAddWorkerMutation,
	useGetDepartmentsQuery,
	useGetLocationsQuery,
	useGetUnitsQuery,
} from '../../store/api';
import { Modal, Form, Input, Select, Button, Alert, message } from 'antd';
import { useTranslation } from 'react-i18next';

const AddWorkerPopup = ({ item, isShow, handleClose }) => {
	const [messageApi, contextHolder] = message.useMessage();
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const department = Form.useWatch('department', form);
	const location = Form.useWatch('location', form);
	const [modalData, setModalData] = useState();
	const [addWorker, { data, isSuccess }] = useAddWorkerMutation();
	const { data: departmentList } = useGetDepartmentsQuery({ locationId: location });
	const { data: getUnitData } = useGetUnitsQuery({ companyDepartmentId: department });
	const { data: locationData } = useGetLocationsQuery();

	useEffect(() => {
		form.setFieldValue('department', '');
	}, [location]);

	useEffect(() => {
		if (data?.message) {
			messageApi.open({ type: 'warning', content: data?.message });
		}
	}, [data]);

	const addWorkerData = async (data) => {
		const res = await addWorker(data);
		if (res.data) {
			form.resetFields();
			setModalData(false);
			handleClose();
		}
	};

	const openModal = () => {
		setModalData(true);
	};

	const closeButton = () => {
		setModalData(false);
	};

	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	return (
		<>
			<Button onClick={openModal} size="sm" type="link" className="me-2">
				{t('add_employee')}
			</Button>
			{contextHolder}
			<Modal
				title={t('addEmployeeModal.title')}
				open={modalData}
				okButtonProps={{
					htmlType: 'submit',
					form: 'custom-modal-form',
				}}
				onCancel={closeButton}
			>
				<Form
					id="custom-modal-form"
					name="basic"
					layout="vertical"
					initialValues={{
						remember: true,
					}}
					onFinish={addWorkerData}
					onFinishFailed={onFinishFailed}
					autoComplete="off"
					form={form}
				>
					<Form.Item
						label={t('addEmployeeModal.phone')}
						name="phone_number"
						rules={[
							{
								required: true,
								message: 'Telefon numarası zorunludur.',
								minLength: 10,
								maxLength: 13,
								pattern:
									/^(\+90|0)?\s*(\(\d{3}\)[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2}|\(\d{3}\)[\s-]*\d{3}[\s-]*\d{4}|\(\d{3}\)[\s-]*\d{7}|\d{3}[\s-]*\d{3}[\s-]*\d{4}|\d{3}[\s-]*\d{3}[\s-]*\d{2}[\s-]*\d{2})$/,
							},
						]}
					>
						<Input name="phone_number" placeholder="+905554443322" />
					</Form.Item>
					<Form.Item
						label={t('addEmployeeModal.location')}
						rules={[{ required: true }]}
						name="location"
					>
						<Select
							placeholder={t('addEmployeeModal.please_select')}
							options={locationData?.locations || []}
							fieldNames={{ label: 'location_name', value: 'id' }}
						/>
					</Form.Item>
					<Form.Item
						label={t('addEmployeeModal.department')}
						rules={[{ required: true }]}
						name="department"
					>
						<Select
							placeholder={t('addEmployeeModal.please_select')}
							options={departmentList?.departments || []}
							fieldNames={{ label: 'department_name', value: 'id' }}
						/>
					</Form.Item>
					{getUnitData && getUnitData.data.units.length > 0 && (
						<Form.Item label="Bağlı bulunan birim" rules={[{ required: false }]} name="unit">
							<Select
								placeholder="Search to Select"
								options={getUnitData?.data.units || []}
								fieldNames={{ label: 'unitName', value: 'id' }}
							/>
						</Form.Item>
					)}
				</Form>
			</Modal>
		</>
	);
};

export default AddWorkerPopup;
