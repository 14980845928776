import React, { useState, useEffect } from 'react';
import { Modal, Select, Button, Spin, Alert, Form, FloatButton } from 'antd';
import { FileAddFilled } from '@ant-design/icons';
import {
	useGetAllCompanyQuery,
	useGetAllSurveyForAdminQuery,
	useInsertCompanySurveyMutation,
} from './../../../store/api';

const { Option } = Select;

const CompanyAndSurveySelectModal = ({ open, onSurveyListUpdate }) => {
	const [isModalVisible, setIsModalVisible] = useState(open);
	const [form] = Form.useForm();

	const {
		data: surveys,
		error: errorSurveys,
		isLoading: loadingSurveys,
		refetch: refetchSurveys,
	} = useGetAllSurveyForAdminQuery();

	const {
		data: companies,
		error: errorCompanies,
		isLoading: loadingCompanies,
	} = useGetAllCompanyQuery();

	const [
		insertCompanySurvey,
		{
			data: insertCompanySurveyData,
			error: insertCompanySurveyError,
			isLoading: insertCompanySurveyLoading,
		},
	] = useInsertCompanySurveyMutation();

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleOk = () => {
		form.submit();
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFinish = async (values) => {
		const { companyId, surveyGuid } = values;
		try {
			await insertCompanySurvey({ companyId, surveyGuid });
			setIsModalVisible(false);
			onSurveyListUpdate(); // Anket listesini güncellemek için callback fonksiyonunu çağır
			refetchSurveys();
		} catch (error) {
			console.error('An error occurred while inserting company survey:', error);
		}
	};

	return (
		<>
			<FloatButton
				onClick={showModal}
				icon={<FileAddFilled />}
				type="primary"
				style={{ insetInlineEnd: 94 }}
			/>
			<Modal
				title="Şirket ve Anket Seç"
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
			>
				{loadingCompanies || loadingSurveys ? (
					<Spin tip="Yükleniyor..." />
				) : errorCompanies ? (
					<Alert message="Hata" description={errorCompanies} type="error" showIcon />
				) : errorSurveys ? (
					<Alert
						message="Hata"
						description="Anket verileri yüklenirken bir hata oluştu."
						type="error"
						showIcon
					/>
				) : (
					<Form form={form} onFinish={onFinish}>
						<Form.Item
							name="companyId"
							rules={[{ required: true, message: 'Lütfen bir şirket seçin!' }]}
						>
							<Select style={{ width: '100%', marginBottom: '16px' }} placeholder="Şirket seçin">
								{companies.company.map((company) => (
									<Option key={company.id} value={company.id}>
										{company.company_name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							name="surveyGuid"
							rules={[{ required: true, message: 'Lütfen bir anket seçin!' }]}
						>
							<Select style={{ width: '100%' }} placeholder="Anket seçin">
								{surveys.result.map((survey) => (
									<Option key={survey.id} value={survey.surveyGuid}>
										{survey.SName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</Form>
				)}
			</Modal>
		</>
	);
};

export default CompanyAndSurveySelectModal;
