import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	useAddUserMutation,
	useGetCompanyUsersQuery,
	useGetWorkerListForAdminQuery,
} from '../../../store/api';
import { Col, Row, FloatButton, Table, Modal, Space, Input, Form, Button, Badge } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';

const CompanyDetail = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { companyId } = useParams();
	const { data, refetch } = useGetCompanyUsersQuery(companyId);
	const [addUser, { isSuccess, isError }] = useAddUserMutation();

	const [form] = Form.useForm();
	const showModal = () => {
		setIsModalOpen(true);
	};

	// useEffect(() => {
	// 	getWorkerList;
	// }, []);

	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const onFinish = async (values) => {
		const val = { ...values, company_id: companyId };
		const res = await addUser(val);
		console.log(res);
		if (res.data.isSuccess) {
			setIsModalOpen(false);
			refetch();
		}
	};
	const onFinishFailed = (errorInfo) => {
		console.log('Failed:', errorInfo);
	};

	const columns = [
		{
			title: 'Kullanıcı ID',
			dataIndex: 'id',
			key: 'id',
		},
		{
			title: 'Kullanıcı E-posta',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Kullanıcı Adı',
			dataIndex: 'name',
			key: 'name',
		},
	];

	return (
		<>
			<FloatButton
				icon={<PlusCircleFilled />}
				type="primary"
				style={{ insetInlineEnd: 14 }}
				onClick={showModal}
			/>

			<Col xs={24} xl={16}>
				<Table tableLayout="auto" dataSource={data?.user || []} columns={columns} rowKey={'id'} />
			</Col>

			<Modal
				title="Yeni Kullanıc Ekle"
				open={isModalOpen}
				okButtonProps={{
					htmlType: 'submit',
					form: 'create-user-modal-form',
				}}
				onCancel={handleCancel}
			>
				<Space direction="vertical" style={{ width: '100%', marginTop: 20 }}>
					<Form
						id="create-user-modal-form"
						name="basic"
						layout="vertical"
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						autoComplete="off"
						form={form}
					>
						<Form.Item
							name="user_name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı e-posta giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıcı e-posta adresi" name="user_name" />
						</Form.Item>
						<Form.Item
							name="name"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı adını giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıc Adı" name="name" />
						</Form.Item>
						<Form.Item
							name="user_type"
							rules={[
								{
									required: true,
									message: 'Lütfen kullanıcı tipini giriniz!',
								},
							]}
						>
							<Input placeholder="Kullanıcı Tipi" name="email" />
						</Form.Item>
					</Form>
				</Space>
			</Modal>
		</>
	);
};

export default CompanyDetail;
