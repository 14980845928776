import React, { useEffect, useState, useRef } from 'react';
import { useGetWorkersListQuery, useUpdateWorkerMutation } from '../../store/api';
import { Button, Space, Table, Badge, Switch, Col, Tour } from 'antd';
import AddWorkerPopup from './addWorkersSingle';
import UploadWithExcel from './uploadWithExcel';
import AddUnit from './AddUnit';
import PageTitle from '../../components/PageTitle';
import { useTranslation } from 'react-i18next';
import EditModal from './editModal';

const WorkersList = () => {
	const [pagination, setPagination] = useState({ limit: 10, offset: 0 });
	const { t } = useTranslation();
	const { data, refetch } = useGetWorkersListQuery(pagination);
	const [updateWorker, { isLoading }] = useUpdateWorkerMutation();
	const [modalData, setModalData] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openTour, setOpenTour] = useState(false);

	// Refs for tour targets
	const titleRef = useRef();
	const addButtonsRef = useRef();
	const tableRef = useRef();

	useEffect(() => {
		// Show tour only on first visit to workers list page
		const hasSeenWorkersTour = localStorage.getItem('hasSeenWorkersTour');
		if (!hasSeenWorkersTour) {
			setOpenTour(true);
			localStorage.setItem('hasSeenWorkersTour', 'true');
		}
	}, []);

	const steps = [
		{
			title: t('tour.workers_page.title'),
			description: t('tour.workers_page.description'),
			target: () => titleRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.workers_add.title'),
			description: t('tour.workers_add.description'),
			target: () => addButtonsRef.current,
			nextButtonProps: {
				style: { background: '#1890ff', borderColor: '#1890ff', color: 'white' },
				children: t('tour.next'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
		{
			title: t('tour.workers_table.title'),
			description: t('tour.workers_table.description'),
			target: () => tableRef.current,
			nextButtonProps: {
				style: { background: '#52c41a', borderColor: '#52c41a', color: 'white' },
				children: t('tour.finish'),
			},
			prevButtonProps: {
				style: { marginRight: 8 },
				children: t('tour.previous'),
			},
		},
	];

	const showModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const handleChange = (isActive, id) => {
		const status = isActive === 1 ? 0 : 1;
		const data = { isActive: status, id: id };
		updateWorker(data);
		refetch();
	};

	const onChangePage = (page, pageSize) => {
		setPagination({ limit: pageSize, offset: (page - 1) * pageSize });
	};

	const columns = [
		{
			title: t('phone'),
			dataIndex: 'phone_number',
			key: 'phone_number',
		},
		{
			title: t('location'),
			dataIndex: 'company_location_name',
			key: 'company_location_name',
		},
		{
			title: t('departmant'),
			dataIndex: 'department_name',
			key: 'department_name',
		},
		{
			title: t('unit'),
			dataIndex: 'unit_name',
			key: 'unit_name',
		},
		{
			title: t('state'),
			dataIndex: 'isActive',
			key: 'isActive',
			render: (item, row) => (
				<>
					<Badge
						style={{ backgroundColor: row.isActive === 1 ? '#52c41a' : '' }}
						count={row.isActive === 1 ? t('active') : t('passive')}
					/>
				</>
			),
		},
		{
			title: t('edit'),
			key: 'delete',
			sorter: true,
			render: (item, record) => (
				<Space size="middle">
					<Switch
						onClick={() => handleChange(item.isActive, item.id)}
						checked={record.isActive}
					></Switch>
					<EditModal data={record} />
				</Space>
			),
		},
	];

	return (
		<>
			<Col xs={23} xl={22}>
				<div ref={titleRef}>
					<PageTitle title={t('report.title')}>
						<div ref={addButtonsRef}>
							<AddUnit
								onClose={() => {
									refetch();
								}}
							/>
							<AddWorkerPopup
								handleClose={() => {
									refetch();
									setModalData(undefined);
								}}
							/>
							<Button disabled type="primary" onClick={showModal}>
								{t('add_employee_with_excel')}
							</Button>
						</div>
					</PageTitle>
				</div>
			</Col>
			<UploadWithExcel isShow={isModalOpen} onClose={closeModal} />
			<Col xs={23} xl={22} styles={{ marginBottom: 20 }}>
				<div ref={tableRef}>
					<Table
						rowKey="id"
						dataSource={data?.workersList || []}
						columns={columns}
						pagination={{ total: data?.total, onChange: onChangePage, showSizeChanger: true }}
					/>
				</div>
			</Col>
			<Tour open={openTour} onClose={() => setOpenTour(false)} steps={steps} />
		</>
	);
};

export default WorkersList;
