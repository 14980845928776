import { Card, Form, Select, Row, Col, Button, Collapse, List } from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import SegmentGraph from './segmentGraph';
import StatusCard from './statusGraph';
import ActionAnalysis from './actionAnalysis';
import { useTranslation } from 'react-i18next';

const SecondReportScreen = () => {
	const { id } = useParams();
	const Navigate = useNavigate();
	const { t } = useTranslation();
	const secondReports = () => {
		Navigate(`/survey-detail/${id}`);
	};
	return (
		<>
			<Col lg={22}>
				<Row justify="space-between" align="middle">
					<Col lg={18}>
						<h1>{t('surveyDetail.detailTitle')}</h1>
					</Col>
					<Col lg={4} align="right">
						<Button type="primary" size="sm" onClick={() => secondReports()}>
							{t('surveyDetail.reportButton')}
						</Button>
					</Col>
				</Row>
			</Col>
			<Col style={{ marginTop: 20 }} lg={22}>
				<SegmentGraph />
			</Col>
			<Col style={{ marginTop: 20 }} lg={22}>
				<StatusCard />
			</Col>
			<Col style={{ marginTop: 20 }} lg={22}>
				<Card title="Statü bazında gelişim">
					<ActionAnalysis />
				</Card>
			</Col>
		</>
	);
};

export default SecondReportScreen;
